<template>
  <div class="d-flex flex-column">
    <v-card
      v-if="src"
      outlined
      class="d-flex flex-grow-1"
    >
      <iframe
        :src="src"
        class="iframe flex-grow-1"
        allowfullscreen
      />
    </v-card>
    <v-card
      v-else
      outlined
    >
      <no-content-screen
        :title="$t('performance.no-content-screen.title')"
        :sub-title="$t('performance.no-content-screen.subtitle')"
      />
    </v-card>
  </div>
</template>

<script>
import NoContentScreen from '@/components/NoContentScreen.vue'
import GET_REPORT_ID from './GetReportId.gql'

const BASE_URL = 'https://datastudio.google.com/embed/reporting'

export default {
  components: { NoContentScreen },
  data: function () {
    return {
      company: {}
    }
  },
  computed: {
    src () {
      return this.company.googlePerformanceReportId && `${BASE_URL}/${this.company.googlePerformanceReportId}`
    }
  },
  apollo: {
    company: {
      query: GET_REPORT_ID,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>

<style scoped>
.iframe{
  padding: 0px;
  margin: 0px;
}
</style>
